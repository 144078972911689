import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;

  ${media.lessThan('sm')`
    padding-top: 100px;
    padding-bottom: 80px;
  `}

  ${media.greaterThan('xl')`
    max-width: 520px;
    margin-right: 55%;
  `}
`

const EtapesAchatImmo = () => (
  <>
    <StyledContainer>
      <Header max={100}>
        Les 6 étapes d’un achat immobilier
      </Header>
      <Subtitle>
        Franchir le pas d’un achat immobilier peut parfois faire peur, notamment si on ne connaît pas précisément toutes les étapes à suivre.
      </Subtitle>
      <Description>
        L’achat d’un bien immobilier implique effectivement de penser à beaucoup de choses. Voilà pourquoi Folhomee vous accompagne dans cette démarche cruciale de votre vie, tout au long de votre achat immobilier.
      </Description>
    </StyledContainer>
  </>
)

export default EtapesAchatImmo
