import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import KeysIcon from '../../../assets/keys.inline.svg'
import PriceIcon from '../../../assets/prix.inline.svg'
import HouseIcon from '../../../assets/house.inline.svg'
import FamilyIcon from '../../../assets/family.inline.svg'
import ContractIcon from '../../../assets/contract.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 32px 0;

  ${media.greaterThan('sm')`
    gap: 16px;
    margin-left: 70px;
  `}

  & > svg {
    height: 60px;

    ${media.greaterThan('sm')`
      height: 80px;
      width: 80px;
    `}
  }
`

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  align-items: center;
`

const AchatImmoActe = () => (
  <HomeContainer max={1080}>
    <HomeSectionTitle>
      Le contenu de l’acte de vente
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Liste non-exhaustive des mentions dans une acte de vente :
    </HomeSectionSubtitle>
    <LineContainer>
      <ImageContainer>
        <FamilyIcon />
      </ImageContainer>
      <HomeSectionDescription>
        Les coordonnées complètes du vendeur et de l’acquéreur et l’adresse du bien
      </HomeSectionDescription>
    </LineContainer>
    <LineContainer>
      <ImageContainer>
        <HouseIcon />
      </ImageContainer>
      <HomeSectionDescription>
        Les équipements annexes, l’origine du bien immobilier et l’existence d’une hypothèque ou d’une servitude le cas échéant
      </HomeSectionDescription>
    </LineContainer>
    <LineContainer>
      <ImageContainer>
        <PriceIcon />
      </ImageContainer>
      <HomeSectionDescription>
        Le prix de vente, le mode financement et le montant des honoraires du notaire
      </HomeSectionDescription>
    </LineContainer>
    <LineContainer>
      <ImageContainer>
        <KeysIcon />
      </ImageContainer>
      <HomeSectionDescription>
        La date de disponibilité du logement
      </HomeSectionDescription>
    </LineContainer>
    <LineContainer>
      <ImageContainer>
        <ContractIcon />
      </ImageContainer>
      <HomeSectionDescription>
        L’acte de vente précise également <strong>tous les diagnostics obligatoires effectués</strong> (plomb, performance énergétique, amiante, etc).
      </HomeSectionDescription>
    </LineContainer>
  </HomeContainer>
)

export default AchatImmoActe
