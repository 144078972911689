import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { get, find, isEqual } from 'lodash'

import media from '../../../utils/media'

const getImage = (data, image) =>
  find(get(data, 'backgrounds.nodes'),
    ({ name }) => isEqual(name, image))

const AchatImmoImage = ({ name, ...props }) => {
  const data = useStaticQuery(graphql`{
    backgrounds: allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "achat-immo"}}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }`)

  return (
    <GatsbyImage
      alt={`${name}-achatimmo-image`}
      image={get(getImage(data, name), 'childImageSharp.gatsbyImageData')}
      {...props} />
  )
}

AchatImmoImage.propTypes = {
  name: PropTypes.string.isRequired
}

const StyledImage = styled(AchatImmoImage)`
  width: 207px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  ${media.lessThan('lg')`
    width: 100%;
    height: 250px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px;
  `}
  ${media.lessThan('md')`
    height: 150px;
`}
`

export default StyledImage
