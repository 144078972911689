import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledButtonContainer = styled(ButtonContainer)`
  margin-bottom: 40px
`

const AchatImmoCourtier = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "achat-immo/etape1.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      fixed={true}
      imageData={placeholderImage}
      backgroundColor='white'>
      <HomeSectionTitle>
        Un courtier pour vous accompagner dans votre financement
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Pour la validation de votre financement, il est également vivement conseillé de faire appel à un courtier.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Encore une fois, Folhomee vous facilite la vie grâce à un réseau de courtiers partenaires expérimentés pour valider votre financement et aboutir à votre acquisition.
      </HomeSectionDescription>
      <StyledButtonContainer>
        <LaunchButton
          link='/services/emprunt-immobilier/'
          title='Me faire accompagner par un courtier'
          background='blue' />
      </StyledButtonContainer>
      <HomeSectionDescription>
        Gardez également à l’esprit que de nombreux dispositifs existent pour financer votre bien, il n’y a pas qu’un seul <span>type de prêt immobilier. </span>
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default AchatImmoCourtier
