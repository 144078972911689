import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(ImageSection)`
  max-height: fit-content;
`

const AchatImmoFourthStep = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "achat-immo/etape4.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <StyledImageSection
      small={true}
      right={false}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle tag='h2'>
        Quatrième étape, obtenir un financement pour l’achat immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Vient à présent le moment d’obtenir un financement pour votre futur achat immobilier.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Pour pouvoir rechercher un prêt immobilier, il faut obligatoirement avoir signé un compromis ou un acte de vente. La signature de l’acte définitif de vente chez le notaire implique, de fait, d’avoir obtenu son financement immobilier.
        <br />&nbsp;<br />Ainsi, une fois que le compromis de vente est signé, vous pouvez vous rendre <strong>directement auprès de votre établissement bancaire, ou auprès d’un courtier.</strong> Différents documents sont alors nécessaires : justificatifs de vos revenus, avis d’imposition, détails concernant l’achat immobilier…
      </HomeSectionDescription>
    </StyledImageSection>
  )
}

export default AchatImmoFourthStep
