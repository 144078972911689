import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { map } from 'lodash'

import media from '../../../utils/media'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import ProgramsProfitsImage from '../../atoms/ProgramsProfitsImage'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DataContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 32px auto;
  justify-content: space-around;
  gap: 24px;


  ${media.lessThan('md')`
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `}

  ${media.greaterThan('sm')`
    margin: 40px auto;
  `}
`

const PartContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  

  & div.gatsby-image-wrapper {
    width: 60px;
    margin-bottom: 8px;

    ${media.greaterThan('sm')`
      width: 80px;
    `}
  }
`

const PartTitle = styled(HomeSectionSubtitle)`
  margin: 8px auto 0;
  text-align: center;
  font-size: 16px;

  ${media.lessThan('sm')`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
  `}
`

const PartDescription = styled(HomeSectionDescription)`
  text-align: center;
`

const ProfitsPart = ({ data }) => map(data, ({ title, description, image }) => (
  <PartContainer key={`programs-profits-${title}`}>
    <ProgramsProfitsImage name={image} />
    <PartTitle>{title}</PartTitle>
    <PartDescription dangerouslySetInnerHTML={{ __html: description }} />
  </PartContainer>
))

const ProfitsData = ({ data }) => (
  <DataContainer>
    <ProfitsPart data={data} />
  </DataContainer>
)

ProfitsData.propTypes = {
  data: PropTypes.array.isRequired
}

export default ProfitsData
