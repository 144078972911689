import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SEO from '../components/atoms/Seo'
import media from '../utils/media.js'
import JsonLD from '../components/atoms/JsonLD'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import SiteSection from '../components/atoms/SiteSection'
import AchatImmoActe from '../components/organisms/AchatImmoActe'
import AchatImmoPret from '../components/organisms/AchatImmoPret'
import AchatImmoBudget from '../components/organisms/AchatImmoBudget'
import AchatImmoCourtier from '../components/organisms/AchatImmoCourtier'
import AchatImmoFolhomee from '../components/organisms/AchatImmoFolhomee'
import AchatImmoFirstStep from '../components/organisms/AchatImmoFirstStep'
import AchatImmoThirdStep from '../components/organisms/AchatImmoThirdStep'
import AchatImmoFifthStep from '../components/organisms/AchatImmoFifthStep'
import AchatImmoSixthStep from '../components/organisms/AchatImmoSixthStep'
import AchatImmoSecondStep from '../components/organisms/AchatImmoSecondStep'
import AchatImmoFourthStep from '../components/organisms/AchatImmoFourthStep'
import AchatImmoTopSection from '../components/organisms/AchatImmoTopSection'
import { useSiteMetadata } from '../utils/seoTools'
import { deepReplace, replaceGraphKey } from '../utils/deepReplace'

const TITLE = '6 conseils pour un achat immobilier'
const DESCRIPTION = 'Vous souhaitez investir dans l\'immobilier ? Folhomee revient sur les principales étapes de l\'achat immobilier neuf et ancien.'

const LastSection = styled(SiteSection)`
  margin-bottom: 0;
`

const StyledSiteSection = styled(SiteSection)`
  ${media.greaterThan('xl')`
    height: 500px;
  `}
`

const AchatImmo = ({ location }) => {
  const site = useSiteMetadata()
  const { webpage, organization } = deepReplace(site, replaceGraphKey)
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description: DESCRIPTION,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, page département, recherche immobilier, accompagnement'
  }

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
        <JsonLD>
          {([webpage, organization, linkedData])}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <AchatImmoTopSection />
      <SiteSection id='achat-immo-first-step' $first={true}>
        <AchatImmoFirstStep />
      </SiteSection>
      <SiteSection id='achat-immo-budget'>
        <AchatImmoBudget />
      </SiteSection>
      <SiteSection id='achat-immo-courtier'>
        <AchatImmoCourtier />
      </SiteSection>
      <StyledSiteSection id='achat-immo-etape2'>
        <AchatImmoSecondStep />
      </StyledSiteSection>
      <SiteSection id='achat-immo-folhomee'>
        <AchatImmoFolhomee />
      </SiteSection>
      <SiteSection id='achat-immo-etape3'>
        <AchatImmoThirdStep />
      </SiteSection>
      <SiteSection id='achat-immo-etape4'>
        <AchatImmoFourthStep />
      </SiteSection>
      <SiteSection id='achat-immo-pret'>
        <AchatImmoPret />
      </SiteSection>
      <StyledSiteSection id='achat-immo-etape5'>
        <AchatImmoFifthStep />
      </StyledSiteSection>
      <SiteSection id='achat-immo-acte'>
        <AchatImmoActe />
      </SiteSection >
      <LastSection id='achat-immo-etape6' $last={true}>
        <AchatImmoSixthStep />
      </LastSection>
      <Footer noImage={true} noForm={true} />
    </>
  )
}

AchatImmo.propTypes = {
  location: PropTypes.object.isRequired
}

export default AchatImmo
