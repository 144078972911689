import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(ImageSection)`
  max-height: fit-content;
`

const AchatImmoFifthStep = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "achat-immo/etape5.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <StyledImageSection
      small={true}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        Cinquième étape, signatures devant le notaire
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        En cinquième étape de ces conseils pour un achat immobilier, arrive le moment de la signature de l’acte de vente.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        La signature de l’acte de vente a, de fait, la même valeur pour les biens immobiliers neufs ou anciens. Après la signature de ce document, l’acheteur devient propriétaire du bien. L’unique différence est que pour un bien ancien, la signature de l’acte de vente découle en une remise des clés, tandis que dans le neuf, l’acheteur doit attendre la livraison du programme.
      </HomeSectionDescription>
    </StyledImageSection>
  )
}

export default AchatImmoFifthStep
