import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import ProfitsData from '../../molecules/ProfitsData'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DATA = [{
  title: 'Ne pas dépasser 35% d’endettement',
  image: 'revenufiscal',
  description: 'Voir les détails du <span>taux d’endettement</span>'
}, {
  title: 'Emprunter sur maximum 25 ans',
  image: 'target',
  description: 'pour une résidence principale et 20 ans pour un investissement locatif.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const AchatImmoPret = () => (
  <Container>
    <HomeSectionTitle>
      L’obtention d’un prêt immobilier, ce qu’il faut savoir
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Globalement, pour l’obtention d’un prêt immobilier, certains éléments clés doivent être respectés :
    </HomeSectionSubtitle>
    <ProfitsData data={DATA} />
    <HomeSectionSubtitle>
      À noter qu’il existe également différents types de prêts immobiliers en fonction des profils et de la situation de chaque acheteur.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Dans le cadre d’un programme immobilier neuf, vous pouvez notamment bénéficier du <span>prêt à taux zéro</span>, pouvant être cumulé avec une <strong>réduction de la TVA à 5,5% au lieu de 20%.</strong>
      <br />&nbsp;<br />Le financement dans le cadre d’un achat immobilier est une étape très importante, il est essentiel que vous soyez très organisé et réactif pour interroger différentes banques avec l’aide ou non d’un courtier. Les délais peuvent être importants, il faut donc s’y prendre le plus tôt possible.
    </HomeSectionDescription>
  </Container>
)

export default AchatImmoPret
