import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import ProfitsData from '../../molecules/ProfitsData'
import HomeContainer from '../../atoms/HomeContainer'
import AchatImmoImage from '../../atoms/AchatImmoImage'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(10, 45, 147, 0.2);
  background-color: #ffffff;
  text-decoration: none;
  height: 100%;
  width: 100%;
  margin-bottom: 24px;
  ${media.greaterThan('lg')`
    flex-direction: row;
  `}
`

const StyledHomeSectionSubtitle = styled(HomeSectionSubtitle)`
  margin-bottom: 40px;
`

const Container = styled.div`
  width: 100%;
  max-width: ${({ max }) => max}px;
  margin: 0 auto; 
  padding: 0 24px;

  ${media.lessThan('sm')`
    padding: 0px 24px;
  `}

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}
  ${media.greaterThan('md')`
    padding: 0 72px;
  `}
  ${media.greaterThan('lg')`
    padding: 0 120px;
  `}
`

const Content = styled.div`
  padding: 24px 16px;
  ${media.greaterThan('lg')`
    width: 800px;
    align: right;
    max-width: ${({ max }) => max}px;
    padding: 40px;
  `}
`

const DetailContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 23px;
  align-items: center;
  gap: 16px;
  ${media.lessThan('lg')`
    flex-direction: column;
`}
`

const ImageContainer = styled.div`
  display: flex;
  min-width: 60px;
`

const TextContainer = styled.div`
  max-width: 550px;
`

const DATA = [{
  title: 'Les caractéristiques',
  image: 'new2',
  description: 'du futur bien immobilier'
}, {
  title: 'Le prix de vente',
  image: 'economie',
  description: 'avec la possibilité de les déduire de vos impôts'
}, {
  title: 'Les dates prévisionnelles de signature',
  image: 'sign',
  description: 'du contrat définitif'
}, {
  title: 'Les dates de livraison',
  image: 'delivery',
  description: 'de votre achat immobilier neuf'
}]

const AchatImmoThirdStep = () => (
  <HomeContainer max={1200}>
    <Container max={1100}>
      <HomeSectionTitle tag='h2'>
        Troisième étape, faire une offre ou signer le contrat de réservation
      </HomeSectionTitle>
      <StyledHomeSectionSubtitle>
        En fonction du type de bien immobilier que vous souhaitez acquérir, neuf ou ancien, la troisième étape de l’achat immobilier consiste à faire une offre ou bien à signer le contrat de réservation.
      </StyledHomeSectionSubtitle>
    </Container>
    <Card >
      <AchatImmoImage name='etape3' />
      <Content>
        <HomeSectionTitle>
          Achat dans l'ancien
        </HomeSectionTitle>
        <HomeSectionDescription>
          Il s’agira de faire une offre en fonction du prix de vente du bien. Ici, le but est de faire une offre satisfaisante pour le vendeur, tout en restant dans votre budget initial. Pour y parvenir, la meilleure solution est de discuter avec le vendeur ou l’agence immobilière pour en savoir plus sur les objectifs de la vente.
        </HomeSectionDescription>
        <DetailContainer>
          <ImageContainer>
            <StaticImage src='../../../images/achat-immo/fast.png' alt='Gardez en tête que le bien que vous convoitez peut potentiellement recevoir d’autres offres. Voilà pourquoi il est important de vous positionner rapidement dessus : les vendeurs apprécient la réactivité.' />
          </ImageContainer>
          <TextContainer>
            <HomeSectionDescription>
              <strong>Gardez en tête que le bien que vous convoitez peut potentiellement recevoir d’autres offres.</strong> Voilà pourquoi il est important de vous positionner rapidement dessus : les vendeurs apprécient la réactivité.
            </HomeSectionDescription>
          </TextContainer>
        </DetailContainer>
        <HomeSectionDescription>
          En rédigeant une offre d’achat,<strong> vous n’êtes pas encore engagé </strong>à acheter obligatoirement le bien, mais cela permet de le bloquer et de lancer la discussion avec le vendeur.
        </HomeSectionDescription>
        <DetailContainer>
          <ImageContainer>
            <StaticImage src='../../../images/achat-immo/Contract.png' alt='Une fois que votre offre est acceptée par le vendeur, vous aurez alors à signer le compromis de vente qui sera le premier document d’engagement à l’acquisition du bien immobilier. Le compromis de vente est rédigé par un notaire ou un agent immobilier et signé par l’acheteur ainsi que par le vendeur.' />
          </ImageContainer>
          <TextContainer>
            <HomeSectionDescription>
              <strong>Une fois que votre offre est acceptée par le vendeur, vous aurez alors à signer le compromis de vente qui sera le premier document d’engagement à l’acquisition du bien immobilier.</strong> Le compromis de vente est rédigé par un notaire ou un agent immobilier et signé par l’acheteur ainsi que par le vendeur.
            </HomeSectionDescription>
          </TextContainer>
        </DetailContainer>
      </Content>
    </Card>
    <Card >
      <AchatImmoImage name='etape3-2' />
      <Content>
        <HomeSectionTitle>
          Achat dans l’immobilier neuf
        </HomeSectionTitle>
        <HomeSectionDescription>
          La procédure n’est pas la même : dans ce cas, vous devez signer le contrat de réservation avec le promoteur du futur logement, ce qui correspond à un compromis de vente.
          <br />&nbsp;<br />Le contrat de réservation détaille :
          <ProfitsData data={DATA} />
          Lorsque vous signez le contrat de réservation, vous devrez très probablement verser un acompte à hauteur de <strong>5% maximum du prix de l’achat immobilier neuf.</strong>
        </HomeSectionDescription>
      </Content>
    </Card>
  </HomeContainer>
)

export default AchatImmoThirdStep
