import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const TextImageContainer = styled.div`
  display: flex;

  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHomeSectionSubtitle = styled(HomeSectionSubtitle)`
  max-width: 400px;
  margin-bottom:16px;
`

const AchatImmoBudget = () => (
  <HomeContainer>
    <TextImageContainer>
      <Container>
        <HomeSectionTitle>
          Dans un deuxième temps, il convient de définir précisément votre budget.
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          En fonction de votre situation, cela peut vouloir dire déterminer votre apport personnel dans le but d’obtenir un financement par la suite.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          C’est votre capacité de paiement qui va déterminer le type de bien immobilier que vous pouvez vous offrir, donc ne passez pas à côté de cette étape !
          <br></br>Afin d’évaluer votre budget, voici la méthode à adopter :
          <StyledList>
            <li>
              <strong>calculer en premier lieu l’apport personnel,</strong>  en sachant qu’il est recommandé d’avoir entre 7 et 10% du prix d’achat ;
            </li>
            <li>
              <strong>estimer la capacité de financement</strong> afin de voir ce qu’il est possible d’emprunter.
            </li>
          </StyledList>
        </HomeSectionDescription>
      </Container>
      <Container>
        <StaticImage src='../../../images/achat-immo/capacite.png' alt='Vous recevez les annonces sur votre application Folhomee' height='300' />
      </Container>
    </TextImageContainer>
    <ButtonContainer>
      <StyledHomeSectionSubtitle>
        Vous pouvez estimer votre capacité de financement via notre simulateur dès maintenant.
      </StyledHomeSectionSubtitle>
      <LaunchButton
        title='Simuler mon financement immobilier'
        background='brightOrange' />
    </ButtonContainer>
  </HomeContainer>
)

export default AchatImmoBudget
