import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TextContainer = styled.div`
  display: flex;

  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  margin:16px auto 0;
`

const AchatImmoFolhomee = () => (
  <Container>
    <TextContainer>
      <TextContent>
        <HomeSectionTitle>
          Folhomee agrège plus de 1 000 sites immobiliers pour que vous puissiez voir des annonces que vous ne verrez nulle part ailleurs.
        </HomeSectionTitle>
        <HomeSectionSubtitle>
          Après avoir indiqué tous vos critères de recherche, vous recevrez toutes les annonces qui vous correspondent en temps réel, pour être le premier à vous positionner sur un bien.
        </HomeSectionSubtitle>
        <HomeSectionDescription>
          Si vous êtes intéressé par un logement ancien, les critères peuvent d’autant plus être affinés que pour un programme immobilier neuf, dans la mesure où le bien immobilier existe et dispose de ses propres caractéristiques.
          <br></br>Quoi qu’il en soit, le meilleur conseil pour un achat immobilier est certainement de prendre le temps de choisir un bien qui correspond à vos critères établis en amont, dans lequel vous êtes en mesure de vous projeter.
        </HomeSectionDescription>
      </TextContent>
      <ImageContainer>
        <StaticImage src='../../../images/achat-immo/folhomee.png' alt='Vous recevez les annonces sur votre application Folhomee' height='300' />
      </ImageContainer>
    </TextContainer>
    <ButtonContainer>
      <LaunchButton
        title='Lancez votre recherche'
        background='brightOrange' />
    </ButtonContainer>
  </Container>
)

export default AchatImmoFolhomee
