import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import HomeContainer from '../../atoms/HomeContainer'
import AchatImmoImage from '../../atoms/AchatImmoImage'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(10, 45, 147, 0.2);
  margin-top: 24px;
  background-color: #ffffff;
  text-decoration: none;
  height: 100%;
  width: 100%;
  ${media.greaterThan('lg')`
    flex-direction: row;
  `}
`

const Content = styled.div`
  padding: 40px;
  ${media.greaterThan('lg')`
    width: 800px;
    align: right;
    max-width: ${({ max }) => max}px;
    padding: 40px;
  `}
`


const Container = styled.div`
  background-color: ${({ theme }) => get(theme, 'veryLightOrange')}; 
  padding-top: 72px;
  padding-bottom: 72px;
`

const AchatImmoSixthStep = () => (
  <Container>
    <HomeContainer max={1100}>
      <HomeSectionTitle tag='h2'>
        Dernière étape, livraison du logement et remise des clés
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La dernière étape pour un achat immobilier est bien sûr la remise des clés, pour l’ancien, et la livraison du logement pour le neuf.
      </HomeSectionSubtitle>
      <Card >
        <AchatImmoImage name='new' />
        <Content>
          <HomeSectionTitle>
            Si vous investissez dans un programme immobilier neuf
          </HomeSectionTitle>
          <HomeSectionDescription>
            Si vous investissez dans un programme immobilier neuf, c’est le moment de visiter votre logement et vérifier que celui-ci est conforme aux caractéristiques annoncées dans le contrat de réservation. Dans le cas où vous constatez des défauts, ou des manquements au contrat, sachez que vous avez le droit de <strong>demander au promoteur d’effectuer les travaux nécessaires.</strong>
            <br />&nbsp;<br />Après la remise des clés, sachez également que vous êtes protégés par <strong>la garantie de parfait achèvement, la garantie biennale et garantie décennale </strong>dans le cas où vous constatez des défaillances cachées.
          </HomeSectionDescription>
        </Content>
      </Card>
      <Card >
        <AchatImmoImage name='old' />
        <Content>
          <HomeSectionTitle>
            Si votre achat immobilier concerne un logement ancien
          </HomeSectionTitle>
          <HomeSectionDescription>
            Pas de surprises : vous obtenez les clés, le logement est à vous après la signature de l’acte de vente en présence du vendeur et du notaire.
            <br />&nbsp;<br /><strong>Attention :</strong> pensez à visiter le bien un ou deux jours avant la signature de l’acte de vente. Cela vous permettra de valider que l’appartement ou la maison est toujours conforme avant de devenir officiellement propriétaire.
          </HomeSectionDescription>
        </Content>
      </Card>
    </HomeContainer>
  </Container>
)

export default AchatImmoSixthStep
