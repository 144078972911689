import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(ImageSection)`
  max-height: fit-content;
`

const AchatImmoSecondStep = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "achat-immo/etape2.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <StyledImageSection
      small={true}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        Deuxième étape, trouver la perle rare parmi plus de 1 000 sites immobiliers sur l’application Folhomee
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La deuxième étape pour l’achat immobilier est bien évidemment de trouver l’annonce qui vous correspond.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Folhomee vous propose des milliers d’annonces dans l’ancien, mais également d’appartements en cours de construction dans des programmes immobiliers neufs. Le bien de vos rêves se trouve forcément dans notre liste.
      </HomeSectionDescription>
    </StyledImageSection>
  )
}

export default AchatImmoSecondStep
