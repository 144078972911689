import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const AchatImmoFirstStep = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "achat-immo/etape1.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      fixed={true}
      imageData={placeholderImage}
      backgroundColor='white'>
      <HomeSectionTitle tag='h2'>
        Première étape, définir votre projet et votre capacité de financement
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La première étape de votre projet d’achat immobilier est de définir vos envies et vos capacités de financement.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        De toute évidence, il est nécessaire de déterminer vos critères, ce qui va permettre d’orienter votre recherche pour trouver le bien immobilier qui vous correspond.
        <br></br>Pour ce faire, rien de plus simple : <strong>établissez une liste d’éléments indispensables dans votre futur chez vous.</strong> Ces critères pourront être ajoutés à votre recherche afin de l’affiner et potentiellement tomber sur la maison ou l’appartement de vos rêves.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default AchatImmoFirstStep
