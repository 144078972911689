import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import AchatImmoImage from '../../molecules/AchatImmoImage'
import EtapesAchatImmo from '../../molecules/EtapesAchatImmo'

const StyledAchatImmoImage = styled(AchatImmoImage)`
  z-index: -1;
  display: none;
  ${media.greaterThan('xl')`
    display: block;
    height: 604px; 
    top: 220px;
  `}
`

const AchatImmoTopSection = () => (
  <TopSection component={StyledAchatImmoImage}>
    <EtapesAchatImmo />
  </TopSection>
)

export default AchatImmoTopSection
